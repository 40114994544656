<script>
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import BaseImage from '/~/components/base/image/base-image.vue'
import EwProductType from '/~/components/ewallet/product/ew-product-type.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useGiftRecipients } from '/~/composables/gift-recipients'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'checkout-summary-item',
  components: {
    BaseImage,
    EwProductType,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showPointsToBurn: {
      type: Boolean,
      default: false,
    },
    prependEstoreTitleWithPrice: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { getGiftRecipient } = useGiftRecipients()
    const { calculatePointsToBurnOrder } = usePoints()
    const { isBillPaymentsTemplate } = useProvider()
    const { ewalletLabels } = useCms()

    const isEstore = computed(() => props.item.type === 'estore')
    const isCinema = computed(() => props.item.type === 'voucher')

    return {
      getGiftRecipient,
      calculatePointsToBurnOrder,
      isEstore,
      isCinema,
      formatDollar,
      isBillPaymentsTemplate,
      ewalletLabels,
    }
  },
  data() {
    return {
      gift: undefined,
      loading: false,
    }
  },
  computed: {
    hasGift() {
      return this.item.gift && this.gift
    },
    image() {
      if (this.item) {
        const { retailerLogo, productImage } = this.item

        return this.cdn(productImage || retailerLogo).url()
      }
      return null
    },
    title() {
      switch (this.item.type) {
        case 'dining':
          return this.item.name
        default:
          return this.item.retailerName
      }
    },
    price() {
      const { price, priceGst } = this.item

      if (this.isEstore) {
        return priceGst
      } else {
        return price
      }
    },
    shortName() {
      const { physical, value, name } = this.item
      let cardType

      if (this.isEstore) {
        if (this.prependEstoreTitleWithPrice) {
          cardType = name
        } else {
          return name
        }
      } else if (this.isCinema) {
        cardType = 'eVoucher'
      } else if (physical) {
        cardType = 'Physical Gift Card'
      } else {
        cardType = 'eGift Card'
      }

      return `${formatDollar(value)} ${cardType}`
    },
    total() {
      const fullPrice = this.price * this.item.quantity

      if (this.showPointsToBurn) {
        return `${formatPoints(this.calculatePointsToBurnOrder(fullPrice))} PTS`
      } else {
        return formatDollar(fullPrice)
      }
    },
  },
  async mounted() {
    if (this.item && this.item.giftRecipientId) {
      this.loading = true
      this.gift = await this.getGiftRecipient(this.item.giftRecipientId)
      this.loading = false
    }
  },
}
</script>

<template>
  <div class="flex items-center justify-between sm:items-start">
    <div
      v-if="image"
      class="mr-5 flex h-full w-[72px] shrink-0 items-start text-center"
    >
      <base-image
        :src="image"
        :fit="isEstore ? 'contain' : 'cover'"
        alt="offer logo"
        class="h-full w-full object-contain"
      />
    </div>
    <div class="flex-1 overflow-hidden">
      <div class="flex flex-nowrap items-center sm:items-start">
        <div class="flex-1 overflow-x-hidden">
          <div class="truncate font-bold">
            {{ title }}
          </div>
          <div class="truncate text-sm leading-6 sm:mt-[5px]">
            {{ item.quantity }} x {{ shortName }}
          </div>
        </div>
        <div class="ml-6 mt-[15px] text-right text-eonx-neutral-600">
          {{ total }}
        </div>
      </div>
      <template v-if="loading">
        <div
          class="h-5 w-1/3 animate-pulse rounded-md bg-eonx-neutral-50 sm:mt-[5px]"
        />
      </template>
      <template v-else>
        <ew-product-type
          v-if="isEstore"
          class="text-xs text-eonx-neutral-600 sm:mt-[5px]"
          :icon-size="20"
          physical
        >
          <template #physical>Delivered by supplier</template>
        </ew-product-type>
        <ew-product-type
          v-else-if="item.gift && gift"
          class="text-eonx-neutral-600"
          :gift="item.gift"
          :gift-card="!!item.retailerName"
          :physical="item.physical"
        >
          <template #digital>
            <div>Gift to {{ gift.email }}</div>
          </template>
          <template #physical>
            <div>Gift to {{ gift.name }}</div>
          </template>
        </ew-product-type>
        <ew-product-type
          v-else
          class="text-eonx-neutral-600"
          :gift="item.gift"
          :gift-card="!!item.retailerName"
          :physical="item.physical"
        >
          <template #digital>Delivered to {{ ewalletLabels.ewallet }}</template>
          <template #physical>Delivered by AusPost</template>
        </ew-product-type>
      </template>
    </div>
  </div>
</template>
